<script setup lang="ts">
import {AisRefinementList, AisClearRefinements, AisRangeInput, AisPanel} from "vue-instantsearch/vue3/es";
import SharedFilter from "~/components/shared/SharedFilter.vue";

const props = defineProps<{
    listingFilters: any;
}>();
const {listingFilters} = toRefs(props);

const filterData = inject('filterData', ref({
    showFilters: false,
    openFilter: '',
    activeFilters: []
}));

const includePriceFilters = ref(true);
const refinements = ref(['Kategori', 'Alkohol', 'Certificering', 'Drue', 'Emballage', 'Land', 'Mærke', 'Region', 'Størrelse', 'Årgang']);
const firstFilterFound = ref(false);
const offerInput = ref(null) as Ref<HTMLInputElement|null>;

onBeforeMount(() => {
    if (listingFilters.value && listingFilters.value.length > 0) {
        refinements.value = listingFilters.value.filter(value => refinements.value.includes(value));
        includePriceFilters.value = listingFilters.value.includes('price');
    }
});

const refineOffer = (isChecked, refine, range) => {
    if (isChecked) {
        const minval = range.min + 1;
        refine({min: minval, max: range.max});
        activeFiltersState.value['tilbud'] = 1;
    } else {
        refine({min: undefined, max: undefined});
        activeFiltersState.value['tilbud'] = 0;
    }
}

const refinePrices = (currentRefinement, refine) => {
    refine(currentRefinement);
    activeFiltersState.value['price'] = currentRefinement.min || currentRefinement.max ? 1 : 0;
}

const isFirstFilter = (refinement, items) => {
    if (firstFilterFound.value && firstFilterFound.value !== refinement) {
        return false;
    }
    if (items.length > 0) {
        firstFilterFound.value = refinement;
        return true;
    }
    return false;
}

const activeFiltersState = ref({});
provide('activeFiltersState', activeFiltersState);
const activeFilters = computed(() => {
    // summarize object with string: number into one total number
    return Object.values(activeFiltersState.value).reduce((acc, val) => acc + val, 0);
});

const placeFiltersContainer = () => {
    const listContainer = document.getElementById('list-container');
    const filtersContainer = document.getElementById('filters-container');
    if (listContainer && filtersContainer) {
        const rect = listContainer.getBoundingClientRect();
        const topbarHeight = document.getElementById('top-bar')?.clientHeight || 0;
        const headerHeight = document.getElementById('page-header')?.clientHeight || 0;
        const listBarHeight = document.getElementById('list-actions')?.clientHeight || 0;
        let topPos = rect.top * -1 + topbarHeight + headerHeight + listBarHeight;
        filtersContainer.style.top = `${Math.max(listBarHeight, topPos)}px`;
    }
};

const backdropActive = inject('backdropActive') as Ref<boolean>;
const filtersElement = ref(null);
onClickOutside(filtersElement, () => (filterData.value.showFilters = false));
watch(() => filterData.value.showFilters,
    (value) => {
        filterData.value.openFilter = '';
        backdropActive.value = value;

        if (process.client && window.document.body.clientWidth > 768) {
            if (value) {
                placeFiltersContainer();
                window.addEventListener('scroll', placeFiltersContainer);
            } else {
                window.removeEventListener('scroll', placeFiltersContainer);
            }
        } else {
            document.body.style.overflow = value ? 'hidden' : 'auto'
        }
    }
);

function toggleFilterTab(filterId) {
    if (filterData.value.openFilter === filterId) {
        filterData.value.openFilter = '';
        return;
    }
    filterData.value.openFilter = filterId;
}

const clearFilters = (refine) => {
    refine();

    if (offerInput.value) {
        offerInput.value.checked = false;
    }

    if (activeFiltersState.value['price']) {
        activeFiltersState.value['price'] = 0;
    }


}

</script>

<template>
    <div
        ref="filtersElement"
        id="filters-container"
        class="
            flex-col
            items-end
            w-full
            max-h-[80%]
            h-[80%]
            md:h-auto
            md:w-[430px]
            fixed
            md:absolute
            md:right-0
            md:top-0
            bottom-0 md:bottom-auto
            z-45
            px-[20px]
            pt-[10px] pb-[33px] md:px-[33px]
            bg-white
            rounded
        "
        :class="{
            'hidden': !filterData.showFilters,
            'flex': filterData.showFilters
        }"
    >
        <div class="md:hidden w-full flex items-center justify-between mt-[28px]">
            <span class="text-[22px] font-semibold">{{ $t('listing.filter') }}</span>
            <NuxtImg
                src="/close.svg"
                height="17"
                @click="filterData.showFilters = !filterData.showFilters"
                :alt="$t('listing.filter')"/>
        </div>
        <ais-clear-refinements
            v-if="filterData.activeFilters.length > 0"
            class="w-full mt-[16px]"
        >
            <template v-slot="{ canRefine, refine }">
                <button
                    class="focus:outline-none w-full text-center border-none bg-brand-flatGray text-[17px] font-semibold py-[14px]"
                    @click="clearFilters(refine)"
                    :disabled="!canRefine"
                >
                    {{ $t('listing.clearFilters') }} <span class="font-normal text-brand-darkGray">({{ activeFilters }})</span>
                </button>
            </template>
        </ais-clear-refinements>

        <ais-panel ref="filtersElement" :class-names="{
            'ais-Panel': 'flex flex-wrap w-screen lg:flex-col lg:w-full max-w-full overflow-y-scroll',
            'ais-Panel-body': 'w-full'
        }">
            <ais-refinement-list
                v-for="refinement in refinements"
                class="pb-1"
                :attribute="'filterableProperties.' + refinement" :key="refinement"
            >
                <template v-slot="{ items, refine }">
                    <SharedFilter
                        :items="items"
                        :refine="refine"
                        :name="refinement"
                        :active-filter="filterData.openFilter"
                        :firstFilter="isFirstFilter(refinement, items)"
                        :useInstantSearch="true"
                        @filter-selected="toggleFilterTab($event)"
                    />
                </template>
            </ais-refinement-list>
            <ais-range-input class="pb-1" attribute="price.percentage.gross" v-if="includePriceFilters">
                <template v-slot="{ currentRefinement, range, refine }">
                    <div
                        class="accordion filter arrow-bold items-center text-brand-dark py-3 border border-brand-gray border-t-solid w-full bg-white flex justify-end"
                        :class="{
                            'expanded text-brand-orange': 'tilbud' === filterData.openFilter,
                        }"
                        @click="toggleFilterTab('tilbud')"
                    >
                        <span
                            class="pointer-events-none text-[18px] font-medium flex-auto">{{ $t('listing.tilbud') }}</span>
                        <span
                            v-if="offerInput?.checked"
                            class="bg-brand-orange h-[25px] w-[25px] rounded-full text-white text-[16px] flex items-center justify-center mr-[25px] "
                        >
                    1
                </span>
                    </div>
                    <div
                        class="pb-4"
                        :class="{'hidden': 'tilbud' !== filterData.openFilter}"
                    >
                        <div class="flex items-center">
                            <input
                                ref="offerInput"
                                type="checkbox"
                                name="tilbud_filter"
                                id="tilbud_filter"
                                class="bevco-checkbox border-black!"
                                @change.prevent="refineOffer($event.currentTarget.checked, refine, range)"
                            />
                            <label class="cursor-pointer" for="tilbud_filter">{{ $t('listing.showTilbud') }}</label>
                        </div>
                    </div>
                </template>
            </ais-range-input>
            <ais-range-input
                class="pb-1"
                attribute="price.gross"
                v-if="includePriceFilters"
            >
                <template v-slot="{ currentRefinement, range, refine }">
                    <div
                        class="accordion filter arrow-bold items-center text-brand-dark py-3 border border-brand-gray border-t-solid w-full bg-white flex justify-end"
                        :class="{
                            'expanded text-brand-orange': 'price' === filterData.openFilter,
                        }"
                        @click="toggleFilterTab('price')"
                    >
                        <span class="pointer-events-none text-[18px] font-medium flex-auto">
                            {{ $t('order.price') }}
                        </span>
                        <span
                            v-if="activeFiltersState['price']"
                            class="bg-brand-orange h-[25px] w-[25px] rounded-full text-white text-[16px] flex items-center justify-center mr-[25px] "
                        >
                            1
                        </span>
                    </div>
                    <div
                        class="pb-4"
                        :class="{'hidden': 'price' !== filterData.openFilter}"
                    >
                        <form class="ais-RangeInput-form">
                            <label class="ais-RangeInput-label">
                                <input
                                    type="number"
                                    class="border border-solid border-brand-base px-3 py-1 text-sm font-normal w-[120px]"
                                    step="1"
                                    :min="range.min"
                                    :max="range.max"
                                    v-model="currentRefinement.min"
                                    :placeholder="range.min"
                                />
                            </label>
                            <span class="ais-RangeInput-separator text-lg font-medium mx-2">-</span>
                            <label class="ais-RangeInput-label">
                                <input
                                    class="border border-solid border-brand-base px-3 py-1 text-sm font-normal w-[120px]"
                                    type="number"
                                    step="1"
                                    :min="range.min"
                                    :max="range.max"
                                    v-model="currentRefinement.max"
                                    :placeholder="range.max"
                                />
                            </label>
                            <button
                                class="px-3 py-1 bg-brand-primary text-white font-sans uppercase text-xs border-transparent ml-2"
                                @click.prevent="refinePrices(currentRefinement, refine)"
                            >
                                {{ $t('listing.go') }}
                            </button>
                        </form>
                    </div>
                </template>
            </ais-range-input>
        </ais-panel>
    </div>
</template>
